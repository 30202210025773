import axios from 'axios'
import env from 'configuration'
import { toast } from 'react-toastify'
import { removeAuth, removeLocationKey } from './auth-helper'
// import Swal from 'sweetalert2'

const BASE_URL = window.location.origin.includes('loyalyy.com')
  ? `${env.API_URL}/v1`
  : `${window.location.origin}/server/api/v1`

export default async function Api(path, method, option) {
  const url = BASE_URL + path

  return axios[method](url, option?.payload)
    .then((response) => {
      if (option?.notify) toast.success(response.data?.message)
      return { response: response.data }
    })
    .catch((error) => {
      if (
        typeof error?.message == 'string' &&
        error?.message?.includes('401')
      ) {
        toast.error('Your session is expired, login again to continue')
        removeAuth()
        removeLocationKey()
        setTimeout(() => {
          window.location.reload()
        }, 5000)
      }
      if (option?.notify)
        toast.error(error.response?.data?.message || 'Something went wrong')
      return { error: error.response?.data }
    })
}
